<template>
  <div class="container">
    <img src="../assets/img/joinUs/joinUs.png" alt="LinkingInnovations" class="JoinUs-background">
    <div>
      <div class="JoinUs-title">{{ $t('JoinUs.title') }}</div>
      <div class="JoinUs-slider-title">
        <div class="JoinUs-slider-title-position" v-for="(item, index) in positionItems" :key="index"
          @click="activeIndexClick(index)">
          <div class="JoinUs-slider-title-position-title"
            :class="{ 'JoinUs-slider-title-position-title-active': activeIndex === index }">{{ item }}
          </div>
          <div class="JoinUs-slider-title-position-group">
            <div class="JoinUs-slider-title-position-line"
              :class="{ 'JoinUs-slider-title-position-line-active': activeIndex === index }" />
            <div class="JoinUs-slider-title-position-circle-border"
              :class="{ 'JoinUs-slider-title-position-circle-border-active': activeIndex === index }">
              <div class="JoinUs-slider-title-position-circle" />
            </div>
            <div class="JoinUs-slider-title-position-line"
              :class="{ 'JoinUs-slider-title-position-line-active': activeIndex === index }" />
          </div>
        </div>
      </div>
      <div class="JoinUs-position-card">
        <div class="JoinUs-position-card-div" v-for="(positionItem, positionIndex) in position" :key="positionIndex">
          <div class="JoinUs-position-card-information" v-for="(item, index) in positionItem" :key="'C' + index"
            v-show="activeIndex === item.active">
            <div class="JoinUs-position-card-information-title">{{ item.title }}</div>
            <div class="JoinUs-position-card-information-content">
              <div class="JoinUs-position-card-information-content-title">{{ $t('JoinUs.responsibility') }}</div>
              <p class="JoinUs-position-card-information-content-p"
                v-for="(itemOne, indexOne) in item.dutiesResponsibilities" :key="'A' + indexOne">{{ itemOne }}</p>
              <div
                class="JoinUs-position-card-information-content-title JoinUs-position-card-information-content-title-margin">
                {{ $t('JoinUs.specification') }}
              </div>
              <p class="JoinUs-position-card-information-content-p" v-for="(itemTwo, indexTwo) in item.requirements"
                :key="'B' + indexTwo">{{ itemTwo }}</p>
              <p class="JoinUs-position-card-information-content-p JoinUs-position-card-information-content-p-margin">
                {{ $t('JoinUs.location') }}</p>
              <p class="JoinUs-position-card-information-content-p">{{ $t('JoinUs.resume') }}<span
                  class="JoinUs-position-card-information-content-email">{{ $t('Common.email') }}</span></p>
              <div class="JoinUs-position-card-information-content-footer">
                <a-button class="JoinUs-position-card-information-content-button" @click="applyJobs">
                  {{ $t('JoinUs.apply') }}
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinUs",
  data() {
    return {
      positionItems: [this.$t('JoinUs.positionItems.positionOne'), this.$t('JoinUs.positionItems.positionTwo'), this.$t('JoinUs.positionItems.positionThree')],
      activeIndex: 0,
      position: [
        [
          {
            active: 0,
            title: this.$t('JoinUs.position.positionOne.positionItemOne.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionOne.positionItemOne.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionOne.positionItemOne.requirements')
          },
          {
            active: 0,
            title: this.$t('JoinUs.position.positionOne.positionItemTwo.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionOne.positionItemTwo.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionOne.positionItemTwo.requirements')
          }
        ],
        [
          {
            active: 1,
            title: this.$t('JoinUs.position.positionTwo.positionItemOne.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionTwo.positionItemOne.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionTwo.positionItemOne.requirements')

          },
          {
            active: 1,
            title: this.$t('JoinUs.position.positionTwo.positionItemTwo.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionTwo.positionItemTwo.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionTwo.positionItemTwo.requirements')
          }
        ],
        [
          {
            active: 2,
            title: this.$t('JoinUs.position.positionThree.positionItemOne.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionThree.positionItemOne.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionThree.positionItemOne.requirements')
          },
          {
            active: 2,
            title: this.$t('JoinUs.position.positionThree.positionItemTwo.title'),
            dutiesResponsibilities: this.$t('JoinUs.position.positionThree.positionItemTwo.dutiesResponsibilities'),
            requirements: this.$t('JoinUs.position.positionThree.positionItemTwo.requirements')
          }
        ]
      ]
    }
  },
  methods: {
    activeIndexClick(index) {
      this.activeIndex = index;
    },
    //申请工作
    applyJobs() {

    },
  }
}
</script>

<style scoped lang="less">
.JoinUs-title {
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  height: 78px;
  line-height: 78px;
  margin-top: 100px;
  margin-bottom: 66px;
}

.JoinUs-slider-title {
  display: flex;
  justify-content: center;
}

.JoinUs-slider-title-position {
  position: relative;
  height: 68px;
}

.JoinUs-slider-title-position-title {
  height: 42px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 42px;
}

.JoinUs-slider-title-position-group {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.JoinUs-slider-title-position-line {
  width: 79px;
  opacity: 0.3;
  height: 1px;
  background: #22DCA1;
  border: 1px solid #22DCA1;
  transition: all 1s;
}

.JoinUs-slider-title-position-circle-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  opacity: 0.3;
  border: 1px solid #22DCA1;
}

.JoinUs-slider-title-position-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #22DCA1;
}

.JoinUs-slider-title-position-title-active {
  height: 42px;
  font-size: 26px;
  line-height: 42px;
}

.JoinUs-slider-title-position-line-active {
  opacity: 1;
}

.JoinUs-slider-title-position-circle-border-active {
  opacity: 1;
}

.JoinUs-position-card {
  width: 100%;
  padding: 0 240px;
  margin-top: 84px;
  margin-bottom: 154px;
}

.JoinUs-position-card-div {
  display: flex;
  justify-content: space-between;

}

.JoinUs-position-card-information-content-email {
  color: #22DCA1;
}

.JoinUs-position-card-information {
  width: 690px;
  height: auto;
}

.JoinUs-position-card-information-title {
  transition: all 0.8s;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.18);
  font-size: 26px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: left;
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.JoinUs-position-card-information-content {
  padding: 40px;
  background: rgba(255, 255, 255, 0.08);
}

.JoinUs-position-card-information-content-title {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: 600;
  text-align: left;
  line-height: 30px;
}

.JoinUs-position-card-information-content-p {
  font-size: 16px;
  color: #FFFFFF;
  text-align: left;
  line-height: 30px;
}

.JoinUs-position-card-information-content-title-margin {
  margin-top: 10px;
}

.JoinUs-position-card-information-content-p-margin {
  margin-top: 10px;
}

.JoinUs-position-card-information-content-footer {
  text-align: center;
  margin-top: 28px;
}

.JoinUs-position-card-information-content-button {
  width: 150px;
  height: 36px;
  background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

/deep/ .ant-btn:hover {
  border: 1px solid #4DB6AC;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5), 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.JoinUs-background {
  display: block;
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 768px) {
  .JoinUs-title {
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .JoinUs-slider-title {
    display: flex;
    justify-content: center;
  }

  .JoinUs-slider-title-position {
    position: relative;
    height: 26px;
  }

  .JoinUs-slider-title-position-title {
    height: 16px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
  }

  .JoinUs-slider-title-position-group {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .JoinUs-slider-title-position-line {
    width: 30px;
    opacity: 0.3;
    height: 1px;
    background: #22DCA1;
    border: 1px solid #22DCA1;
    transition: all 1s;
  }

  .JoinUs-slider-title-position-circle-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    opacity: 0.3;
    border: 1px solid #22DCA1;
  }

  .JoinUs-slider-title-position-circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #22DCA1;
  }

  .JoinUs-slider-title-position-title-active {
    height: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  .JoinUs-slider-title-position-line-active {
    opacity: 1;
  }

  .JoinUs-slider-title-position-circle-border-active {
    opacity: 1;
  }

  .JoinUs-position-card {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }

  .JoinUs-position-card-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .JoinUs-position-card-information {
    width: 100%;
    height: auto;
  }

  .JoinUs-position-card-information-title {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, 0.18);
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding-left: 0;
  }

  .JoinUs-position-card-information-content {
    padding: 10px 6px 0 10px;
    background: rgba(255, 255, 255, 0.08);
    margin-bottom: 10px;
  }

  .JoinUs-position-card-information-content-title {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 600;
    text-align: left;
    line-height: 16px;
  }

  .JoinUs-position-card-information-content-p {
    font-size: 10px;
    color: #FFFFFF;
    text-align: left;
    line-height: 14px;
  }

  .JoinUs-position-card-information-content-title-margin {
    margin-top: 6px;
  }

  .JoinUs-position-card-information-content-p-margin {
    margin-top: 6px;
  }

  .JoinUs-position-card-information-content-footer {
    text-align: center;
    margin-top: 10px;
  }

  .JoinUs-position-card-information-content-button {
    width: 60px;
    height: 16px;
    background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
    border-radius: 2px;
    font-size: 8px;
    font-weight: 400;
    margin-bottom: 14px;
    color: #333333;
  }

  /deep/ .ant-btn:hover {
    border: 1px solid #4DB6AC;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5), 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .JoinUs-background {
    display: block;
    width: 100%;
    height: 140px;
  }
}
</style>